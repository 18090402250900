<template>
  <div class="modal fade" ref="mdlDetallesPago" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">Detalles del pago {{ abono.id }}</strong>
          </div>
          <button
            type="button" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 text-right col-4">
              <label>ID</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled
                readonly :value="abono.id"
                class="form-control" 
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4 col-4 text-right">
              <label>Fecha</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled 
                :value="formatoFecha(abono.fecha)" 
                class="form-control"
                readonly 
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4 col-4 text-right">
              <label>Hora</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled
                :value="formatoHora(abono.fecha)"
                class="form-control"
                readonly 
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4 col-4 text-right">
              <label>Importe</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled
                :value="hrr.fmon(abono.importe, { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' })"
                class="form-control text-success"
                readonly 
              />
              <template v-if="abono.importe != abono.importe_asignado">
                <div class="text-right">
                  <span class="text-muted font-size-10">
                    Asignado: {{ hrr.fmon(abono.importe_asignado, { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' }) }}
                  </span>
                  <span class="text-info font-size-10">
                    Disponible: {{ hrr.fmon((abono.importe - abono.importe_asignado), { symbol: monedaSistema.simbolo, miles: ',', decimal: '.' }) }}
                  </span>
                </div>
              </template>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4 col-4 text-right">
              <label>Método de pago</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled 
                :value="abono.metodo_pago.nombre"
                readonly class="form-control"
              />
            </div>
          </div>
          <br v-if="abono.banco">
          <div class="row" v-if="abono.banco">
            <div class="col-md-4 col-4 text-right">
              <label>Banco</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled
                :value="abono.banco.nombre"
                class="form-control"
                readonly 
              />
            </div>
          </div>
          <br v-if="abono.banco">
          <div class="row" v-if="abono.banco">
            <div class="col-md-4 col-4 text-right">
              <label>ID de operación</label>
            </div>
            <div class="col-md-6 col-6">
              <input
                type="text" disabled
                :value="abono.id_operacion"
                readonly class="form-control"
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4 col-4 text-right">
              <label>ID de factura</label>
            </div>
            <div class="col-md-6 col-6">
              <template v-for="factura in abono.facturas_pagadas">
                <router-link
                  :to="{
                    name: 'factura',
                    params: { id: factura.id }
                  }"
                  v-if="true"
                  :key="factura.id"
                  data-dismiss="modal"
                >
                  {{ factura.ns }}
                </router-link>
                &nbsp;
              </template>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-9 offset-md-1">
              <label>Nota acerca del pago</label>
              <textarea
                name="nota" rows="5" disabled 
                v-model="abono.nota" readonly 
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbonoSrv from '@/services/AbonoSrv.js'
import moment from 'moment'
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlDetallePago',
  data() {
    return {
      id_abono: null,
      hrr: iu.hrr,
      abono : {
        id: null,
        banco: {
          id:null,
          nombre:''
        },
        cancelado: false,
        fecha: '',
        id_banco: null,
        id_cliente: null,
        id_metodo_pago: null,
        id_operacion: null,
        id_solicitud_instalacion: null,
        id_usuario: null,
        importe: 0,
        importe_asignado: 0,
        metodo_pago:{
          descripcion: '',
          id: null,
          nombre:''
        },
        nota: ''
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  methods: {
    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('LT')
    },
    cargarPago: function() {
      var self = this,
          idAbono = self.id_abono
         
      var params = {
        con_facturas : true,
        con_banco : true
      }

      AbonoSrv.abonoJSON(idAbono, params).then(response => {
        self.abono = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se cargo el abono'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    mostrar(idPago) {
      var self = this
      self.id_abono = idPago
      self.cargarPago()
      const modal = new Modal(this.$refs.mdlDetallesPago)
      modal.show()
    }
  }
}
</script>